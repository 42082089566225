import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import sentryConfig from './sentry.config.json';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

// Tem solution to redirect old UAT to new UAT. Should be deleted once old UAT app is shut down.
if (location?.hostname === "eip-app-uat.azurewebsites.net") {
  location.href = `https://eip-uat.empowerpharmacy.com${location.pathname}`
}

if (location?.hostname !== "localhost" && location?.hostname !== "127.0.0.1") {
  Sentry.init({
    dsn: sentryConfig.dsn,
    release: sentryConfig.release,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.sentryEnvironment,

    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
