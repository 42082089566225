import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { AuthTokens } from '../_models/auth-tokens';
import { AuthTokenService } from '../_services/auth-token.service';
import { RefreshTokenService } from '../_http/refresh-token.service';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthTokensInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  authTokensSubject: BehaviorSubject<AuthTokens> = new BehaviorSubject<AuthTokens>(null);

  constructor(
    private authTokensService: AuthTokenService,
    private refreshTokenService: RefreshTokenService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authTokens = this.authTokensService.getAuthTokens();
    if (authTokens) {
      request = this.addToken(request, authTokens);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.authTokensSubject.next(null);

      const currentAuthTokens = this.authTokensService.getAuthTokens();

      if (currentAuthTokens && currentAuthTokens.refreshToken) {

        return this.refreshTokenService.refreshToken(currentAuthTokens)
          .pipe(
            switchMap((refreshedAuthTokens: AuthTokens) => {
              if (!refreshedAuthTokens || !refreshedAuthTokens.accessToken || !refreshedAuthTokens.refreshToken) {
                this.isRefreshingToken = false;
                return this.unauthorized();
              }

              this.authTokensService.setAuthTokens(refreshedAuthTokens);
              this.isRefreshingToken = false;
              this.authTokensSubject.next(refreshedAuthTokens);
              return next.handle(this.addToken(request, refreshedAuthTokens));
            }),
            catchError((error) => {
              return this.unauthorized();
            }),
            finalize(() => {
              this.isRefreshingToken = false;
            })
          );
      }
      else {
        this.isRefreshingToken = false;
        return this.unauthorized();
      }
    } else {
      return this.authTokensSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, authTokens: AuthTokens): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authTokens.accessToken}`,
        Token: 'ignore'
      }
    });
  }

  private unauthorized(): Observable<any> {
    this.authTokensService.clearAuthTokens();
    this.router.navigate(['/auth/login']);
    return EMPTY;
  }
}
